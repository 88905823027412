import firebase from 'firebase/app';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyBiI05wq9ugR47AmgQgAeyPk5Lqxxplvg4",
  authDomain: "project-management-ct.firebaseapp.com",
  projectId: "project-management-ct",
  storageBucket: "project-management-ct.appspot.com",
  messagingSenderId: "327871677368",
  appId: "1:327871677368:web:250ec91cb72df01529054d"
};

  firebase.initializeApp(firebaseConfig);

  export default firebase;
