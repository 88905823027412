import React, { Component } from 'react';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import IncompletedTaskIcon from '../../assets/incompleted-task.png'
import CompletedTaskIcon from '../../assets/completed-task.png'
import Fade from 'react-reveal/Fade';


import './Task.css'


class Task extends Component {
    constructor(props) {
        super(props);
        this.state = { isCompleted:false }
    }

    toggleComplete = () =>
    {
        this.setState({isCompleted:!this.state.isCompleted})
    }

    render() { 
        return ( 
            <Fade collapse bottom>
                <ListItem button onClick={this.toggleComplete}>
                    <ListItemIcon>
                        <img src={CompletedTaskIcon} className={`Tasker-Icon ${this.state.isCompleted? 'Tasker-Icon-Opacity1':'Tasker-Icon-Opacity0' }  ` }/>
                        <img src={IncompletedTaskIcon} className={`Tasker-Icon ${this.state.isCompleted? 'Tasker-Icon-Opacity0':'Tasker-Icon-Opacity1' }  ` }/>
                    </ListItemIcon>
                    <ListItemText primary="A Task" />
                </ListItem>
            </Fade>
        );
    }
}
 
export default Task;