import React, { Component } from 'react';
import Spreadsheet from "react-spreadsheet";

const data = [
    [{ value: "Vanilla" }, { value: "Chocolate" }],
    [{ value: "Strawberry" }, { value: "Cookies" }],
  ];

class Herr extends Component {
    constructor(props) {
        super(props);
        this.state = { data:[] }
    }
    componentDidMount()
    {
        fetch('https://commercial.herrealtors.com/api/searching-count/cities')
        .then(response => response.json())
        .then(data => this.setData(data));
    }

    setData = (data) =>
    {
        let results = data.results;
        let customData = [];
        customData.push([{value:"City"},{value:"SearchCount"}])

        for(let j=0;j<results.length;j++)
        {
            customData.push([{value:results[j].City},{value:results[j].searchCount}])
        }
        this.setState({data:customData})
    }
    render() { 
        return ( 
        <div>
            <Spreadsheet data={this.state.data} />

        </div> );
    }
}
 
export default Herr;