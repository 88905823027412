import { Button, Grid } from '@material-ui/core';
import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';

import List from '@material-ui/core/List';

import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import TaskIcon from '../assets/task.png';
import DiscussionIcon from '../assets/qaIcon.png';




import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import './Tasker.css'
import Task from './Task/Task';
import Discussion from './Discussion/Discussion';

const Actions = [
    {id:0,icon:TaskIcon,name:'Task'},
    {id:1,icon:DiscussionIcon,name:'Discussion'}
]


const taskerStyles = (theme) => ({
  root: {
    width:'calc(100% - 40px)',
    padding:'20px'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  speedDial: {
    position:'absolute',
    top:'-160px'  //+55px each time an action is added in speed dial.

  },
})

class Tasker extends Component {
    constructor(props) {
        super(props);
        this.state = { newTask:null,isSpeedDialOpen:false,activeTaskAssigner:{id:0,icon:TaskIcon,name:'Task'},
            todos: [ 
                {type:'Task',id:0},
                {type:'Task',id:1},
                {type:'Discussion',id:2}
            ]
        }
    }

    handleSpeedDialOpen = () =>
    {
        this.setState({isSpeedDialOpen:true})
    }

    handleSpeedDialClose = () =>
    {
        this.setState({isSpeedDialOpen:false})
    }

    handleSpeedDialChange = (id) =>
    {
        let activeTaskAssigner = Actions.find((action) => action.id===id);
        this.setState({activeTaskAssigner,isSpeedDialOpen:false})
    }

    handleChange = (e) =>
    {
        this.setState({[e.target.id]:e.target.value})
    }

    add = () =>
    {
        let newTodos = JSON.parse(JSON.stringify(this.state.todos));
        let activeType = this.state.activeTaskAssigner.name;
        newTodos.push({type:activeType,id:newTodos.length});
        this.setState({todos:newTodos})
    }

    handleDescriptionOpen = () =>
    {
        this.setState({isDescriptionOpen:true})
    }

    handleDescriptionClose = () =>
    {
        this.setState({isDescriptionOpen:false})
    }

    render() { 

        const {classes} = this.props;
        return ( 
            <React.Fragment>
                <Grid item xs={12} sm={10} md={4}>
                    <Fade>
                        <Paper>

                            <div className="Tasker-Top-Nav">
                                {this.props.name}
                            </div>

                            <List className={classes.root} aria-label="contacts">
                                {this.state.todos.map((item)=> 
                                item.type==="Task"? <Task handleDescriptionOpen={this.handleDescriptionOpen}/>:
                                item.type==="Discussion"?<Discussion handleDescriptionOpen={this.handleDescriptionOpen}/>:
                                null
                                )}
                            </List>

                            <div className="Tasker-Bottom-Nav">
                                <input
                                type="text"
                                className="Tasker-Add-Input"
                                id='newTask'
                                placeholder='Todo item'
                                name='newTask'
                                value={this.state.newTask}
                                onChange={this.handleChange}
                                />
                                <Button
                                    onClick={this.add}
                                    variant="contained"
                                    className="Tasker-Add-Button"
                                > Add </Button>

                                <div className="Tasker-Speed-Dial-Wrapper">

                                    <SpeedDial
                                        ariaLabel="Add new item"
                                        className={classes.speedDial}
                                        icon={<img src ={ this.state.activeTaskAssigner.icon }/>}
                                        onClose={this.handleSpeedDialClose}
                                        onOpen={this.handleSpeedDialOpen}
                                        open={this.state.isSpeedDialOpen}
                                    >
                                        {Actions.map((action) => (
                                        <SpeedDialAction
                                            key={action.name}
                                            icon={<img src={action.icon} />}
                                            tooltipTitle={action.name}
                                            tooltipOpen
                                            onClick={() => this.handleSpeedDialChange(action.id)}
                                        />
                                        ))}
                                    </SpeedDial>
                                    
                                </div>
                                
                            </div>              
                        </Paper>
                    </Fade>
                </Grid>
                
                {
                    this.state.description &&
                
                    <Fade top>
                        <h1>React Reveal</h1>
                    </Fade>
                }
            </React.Fragment>

        );
    }
}
 
export default withStyles(taskerStyles)(Tasker);