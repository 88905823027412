import LoaderImage from '../assets/LogoBig.png'
import './loader.css'

const Loader = () => {
    return ( 
    
    <div style={{height:'100vh',width:'100vw'}}>
        <div className="loader-div">
            <img src={LoaderImage} className="loader-image"/>
        </div>
    </div> );
}
 
export default Loader;