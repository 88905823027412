import { Fab, Grid } from '@material-ui/core';
import React, { Component } from 'react';
import Tasker from '../components/Tasker';
import AddIcon from '@material-ui/icons/Add';

const Projects = [
    {id:0,name:'Donajo'},
    {id:1,name:'HerRealtors'},
    {id:2,name:'SBS - Step by Step Evaluation'},
    {id:3,name:'LikeNow'},
    {id:4,name:'DrEmil'},
    {id:5,name:'Listrak'},
    {id:6,name:'Opportunity'}
]

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
        <div style={{marginTop:'100px',marginLeft:'20px',width:'calc(100% - 40px)'}}>

            <Fab disabled={true} style={{position:'absolute',top:'20px',right:'40px',background:'#10102A',opacity:'0.5'}}>
                <AddIcon style={{color:'rgb(179 179 179)'}} />
            </Fab>

            <Grid container spacing={3} >
                {
                    Projects.map( (project) =>
                        <Tasker name={project.name}/>
                    )
                }
            </Grid>
        </div> );
    }
}
 
export default Dashboard;