import React, { Component } from 'react';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Fade from 'react-reveal/Fade';

import DiscussionIcon from '../../assets/qa.png'

// import './Discussion.css'


class Discussion extends Component {
    constructor(props) {
        super(props);
        this.state = { isCompleted:false }
    }

    toggleComplete = () =>
    {
        this.setState({isCompleted:!this.state.isCompleted})
    }

    render() { 
        return ( 
            <Fade collapse bottom>
                <ListItem button>
                    <ListItemIcon>
                        <img src={DiscussionIcon} className={`Tasker-Icon` }/>
                    </ListItemIcon>
                    <ListItemText primary="A discussion" />
                </ListItem>
            </Fade>
        );
    }
}
 
export default Discussion;