import React, { Component } from 'react';
import { Route, Router } from 'react-router-dom';
import history from './helpers/history'; 
import Dashboard from './pages/Dashboard';
import Login from './pages/login/login';
import firebase from './helpers/firebase';
import Loader from './components/Loader';
import Herr from './pages/herr/herr';
import HerrCustomCities from './pages/herr/herrCustomCities';

class Routes extends Component {
    constructor(props) {
        super(props);
        this.state = { loggedIn:false,loading:true }
    }

    componentDidMount()
    {
        //loader
        // check firebase login and resolve loader
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
              var uid = user.uid;
              console.log('logged in')
              this.setState({loading:false,loggedIn:true})
              //change LoggedIn Routes
              // ...
            } else {
              // User is signed out
              // ...
              console.log('logged out')
              this.setState({loading:false,loggedIn:false})


            }
        });
    }

    render() { 
        return ( 
            this.state.loading==true?
            <Loader/>
            :
            
            this.state.loggedIn?
            //logged In Routes
            
            <Router history={history}>
                <Route exact path="/" component={Dashboard}/>
                <Route path="/Dashboard" component={Dashboard}/>
            </Router>
            :
            <Router history={history}>
                <Route exact path="/" component={Login}/>
                <Route path="/login" component={Login}/>
                <Route path="/HERR-LIST" component={Herr}/>
                <Route path="/herr-custom-cities" component={HerrCustomCities}/>

            </Router>

         );
    }
}
 
export default Routes; 