import React, { Component } from 'react';
import Spreadsheet from "react-spreadsheet";

class HerrCustomCities extends Component {
    constructor(props) {
        super(props);
        this.state = { data:[] }
    }
    componentDidMount()
    {
        fetch('https://commercial.herrealtors.com/api/searching-count/custom-cities')
        .then(response => response.json())
        .then(data => this.setData(data));
    }

    setData = (data) =>
    {
        let results = data.results;
        let customData = [];
        customData.push([{value:"City"}, {value:"City Id"}, {value:"Property Type"}, {value:"Property Label"} , {value:"Search Count"}])

        for(let j=0;j<results.length;j++)
        {
            let rec = results[j];
            let types = rec.types;
            for(let k=0;k<types.length;k++)
            {
                customData.push([
                    {value:rec.city_label},
                    {value:rec.city_id},
                    {value:types[k].property_type},
                    {value:types[k].label},
                    {value:types[k].count}
                ])
            }
        }
        this.setState({data:customData})
    }
    render() { 
        return ( 
        <div>
            <h1>
                Herr-Realtors Custom Cities Search Count By Property Type
            </h1>
            <br/>
            <Spreadsheet data={this.state.data} />
        </div> );
    }
}
 
export default HerrCustomCities;