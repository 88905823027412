import React, { Component } from 'react';
import firebase from '../../helpers/firebase'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Fade from 'react-reveal/Fade';
import './login.css'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://codingtier.com/">
        Coding Tier
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const loginStyle = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background:'#10102A'
  },
  
 
});



class Login extends Component {
    constructor(props) {
        super(props);
        this.state = { email:null,password:null,error:false }
    }

    isOk = ( ) =>
    {
      let {email,password} = this.state;
      if(email && email.length>0 && password && password.length>0 && this.checkEmail() && this.checkPassword())
      {
        return true;
      }
      else {
        return false;
      }
    }

    handleChange = (e) =>
    {
        this.setState({[e.target.id]:e.target.value,error:false})
    }

    checkEmail = () =>
    {
        if(this.state.email && this.state.email.length>0)
        {
          const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(String(this.state.email).toLowerCase());
        }
        return true;
    }

    checkPassword = () =>
    {
        if(this.state.password && this.state.password.length>0)
        {
            if(this.state.password.length<6)
            {
              return false
            }
            else return true
        }
        return true;   
    }

    handleLogin = (e) =>        // to avoid spoofing
    {
      e.preventDefault();
      if(this.checkEmail && this.checkPassword)
      {
        this.Login();
      }
    }

    Login = () =>
    {
        //start loader
        
      firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password) //.then  listener at routes componentDidMount
      .catch( (error) => { console.log("Stack error ",error ); this.setState({error:'true'}) })
        
    }
    render() { 

        const {classes} = this.props;
        return ( 
            <Fade bottom>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <div className={classes.paper}>
                    <Avatar className={classes.avatar} className="gradient-black-icon">
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5" style={{textAlign:'center'}}>
                        <span className="gradient-blue">  Sign in <br/> CodingTier Portal @v1 </span>
                    </Typography>
                    <form className={classes.form} noValidate>
                        <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange = {this.handleChange}
                        error={!this.checkEmail()}
                        />
                        <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        onChange = {this.handleChange}
                        autoComplete="current-password"
                        error={!this.checkPassword()}

                        />

                        <Fade bottom collapse when={this.state.error}>

                          <div className="LoginInErrorMessage">
                            Wrong Email or Password
                          </div>
                        </Fade>

                        <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                        />

                        <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        className={classes.submit}
                        onClick={this.handleLogin}
                        disabled={!this.isOk()}
                        >
                          <span className={"gradient-black"}> Sign In </span>
                        </Button>

                        <Grid container>
                        <Grid item xs>
                            <Link href="" onClick={(e)=>{e.preventDefault()}} className="disabledLink" variant="body2" >
                              Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="#" variant="body2" onClick={(e)=>{e.preventDefault()}} className="disabledLink">
                            {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                        </Grid>
                    </form>
                    </div>
                    <Box mt={8}>
                    <Copyright />
                    </Box>
                </Container>
            </Fade>
        );
    }
}
 
export default withStyles(loginStyle)(Login);